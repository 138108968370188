import { createTheme, responsiveFontSizes } from '@mui/material'


import { typography } from './typography'
import { components } from './components'
import { darkPalette, lightPalette } from './palette'


const goSchoolDarkTheme = createTheme({
  palette: darkPalette,
  typography,
  components
})

const goSchoolLightTheme = createTheme({
  palette: lightPalette,
  typography,
  components
})

export const goSchoolTheme = responsiveFontSizes(goSchoolDarkTheme)

import { Stack, Typography, Button } from '@mui/material'
import { Trans } from 'react-i18next'
import { useAuthenticationContext, useUserContext } from '@goschool/auth'
import { Scroller, Section } from './Scroller'
import { Testimonials } from './Testimonials'
import { Manifesto } from './Manifesto'
import { DemoRequestForm } from './DemoRequest'
import { LoadingPage } from '@goschool/components'
import { CoursesPage } from '@goschool/courses'


export function LandingPage() {
  const { signIn, signUp } = useAuthenticationContext()
  const { user, state } = useUserContext()

  if (state==='pending') {
    return <LoadingPage />
  }

  if (user!=null) {
    return <CoursesPage />
  }

  return <Scroller>
    <Section>
      <Typography variant="h1" component="h1" lineHeight={1}><Trans i18nKey="landing:valueProp" /></Typography>
      <Typography variant="h3" component="h2" gutterBottom={false}><Trans i18nKey="landing:headline" /></Typography>
      <Stack direction="row" justifyContent="flex-start" my={2} gap={2}>
        <Stack gap={0.5} justifyContent="center" alignItems="center">
          <Typography variant="caption" color="primary"><Trans i18nKey="auth:signUp.message" /></Typography>
          <Button size="small" variant="contained" color="primary" onClick={signUp}><Trans i18nKey="auth:signUp.cta" /></Button>
        </Stack>
        <Stack gap={0.5} justifyContent="center" alignItems="center">
          <Typography variant="caption" color="primary"><Trans i18nKey="auth:signIn.message" /></Typography>
          <Button size="small" variant="outlined" color="primary" onClick={signIn}><Trans
            i18nKey="auth:signIn.cta" /></Button>
        </Stack>
      </Stack>
    </Section>

    <Manifesto />
    <Testimonials />

    <DemoRequestForm />
  </Scroller>
}






import { colors, darken, lighten } from '@mui/material'

export const darkPalette = {
  mode: 'dark',
  background: {
    default: darken(colors.blueGrey['900'], 0.8),
    paper: darken(colors.blueGrey['900'], 0)
  },
  primary: {
    main: colors.blue['300']
  },
  secondary: {
    main: colors.amber['A700']
  }
} as const


export const lightPalette = {
  mode: 'light',
  background: {
    default: lighten(colors.blueGrey['50'], 0.8),
    paper: lighten(colors.blueGrey['50'], 0)
  },
  primary: {
    main: colors.indigo['500']
  },
  secondary: {
    main: colors.amber['500']
  }
} as const

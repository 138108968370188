import type { LinkProps } from '@mui/material/Link'
import { LinkBehavior } from '@goschool/mui'

export const components = {
  MuiLink: {
    defaultProps: {
      component: LinkBehavior
    } as LinkProps
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior
    }
  }
} as const

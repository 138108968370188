import '@fontsource-variable/gabarito'
import '@fontsource-variable/inter'

const designFont = 'Gabarito Variable, sans-serif'
const bodyFont = 'Inter Variable, sans-serif'

export const typography = {
  fontFamily: bodyFont,
  body1: { fontFamily: bodyFont },
  body2: { fontFamily: bodyFont, fontSize: '0.8rem' },
  h1: { fontFamily: designFont },
  h2: { fontFamily: designFont },
  h3: { fontFamily: designFont },
  h4: { fontFamily: designFont },
  h5: { fontFamily: designFont },
  h6: { fontFamily: designFont },
  subtitle1: { fontFamily: designFont },
  subtitle2: { fontFamily: designFont }
} as const
